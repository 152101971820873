import { BrowserRouter, Form, Route, Routes } from "react-router-dom";
import "./App.css";
import { LandingPage } from "./container/Distributor/landing";
import { FormForDistributor } from "./container/Distributor/form/distributorForm";
import { Home } from "./container/home";
import { ReportPage } from "./container/Distributor/report/index"; 
import DynamicTable  from "./container/Distributor/report/dynamicTable";
import { AdminLogin } from "./container/Admin/authentication/admin_login";
import { AdminLandingPage } from "./container/Admin/admin_landing_page";
import { DistributorLogin } from "./container/Distributor/authentication/login";
import { MasterFile } from "./container/Distributor/masterFile";
import { useEffect } from "react";
import { DayReport } from "./container/Distributor/report/dayReport";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home />}></Route> */}
        {/* Admin */}
        <Route
          path="/admin-landing-page"
          element={<AdminLandingPage />}></Route>
        <Route path="/admin-login-page" element={<AdminLogin />}></Route>
        <Route path="/form" element={<FormForDistributor />}></Route>
        <Route path="/admin-report-page" element={<ReportPage />}></Route>

        {/* Distributor */}
        {/* <Route path="/landing" element={<LandingPage />}></Route> */}
        <Route
          path="/"
          element={<DistributorLogin />}></Route>
        <Route path="/form" element={<FormForDistributor />}></Route>
        <Route path="/report-page" element={<ReportPage />}></Route>
        {/* <Route path="/table-filtered" element={<DynamicTable />}></Route> */}
        <Route path="/day-report" element={<DayReport />}></Route>
        <Route path="/master-file" element={<MasterFile />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
