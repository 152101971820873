import { FORM_GET_DESCRIPTION_FAILURE, FORM_GET_DESCRIPTION_SUCCESS, FORM_GET_FILE_FAILURE, FORM_GET_FILE_OFFLINE_FAILURE, FORM_GET_FILE_OFFLINE_SUCCESS, FORM_GET_FILE_SUCCESS, FORM_GET_ITEM_MASTER_FILE_FAILURE, FORM_GET_ITEM_MASTER_FILE_SUCCESS, FORM_GET_SAP_FAILURE, FORM_GET_SAP_SUCCESS, FORM_POST_FAILURE, FORM_POST_SUCCESS } from "./form.actionTypes";

const initialState = {
formdetails:null,
itemMasterFile: [],
sapFile:null,
descriptionFile:null,
formFile: null,
offlineFile: null
};

export default function formReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FORM_POST_SUCCESS:
      return {
        ...state,
        formdetails : payload
      }

    case FORM_POST_FAILURE:
      return {
        formdetails : null
      }
 

      case FORM_GET_ITEM_MASTER_FILE_SUCCESS:
        return {
          ...state,
          itemMasterFile : payload
        }
  
      case FORM_GET_ITEM_MASTER_FILE_FAILURE:
        return {
          itemMasterFile : null
        }

        case FORM_GET_SAP_SUCCESS:
          return {
            ...state,
            sapFile : payload
          }
    
        case FORM_GET_SAP_FAILURE:
          return {
            sapFile : null
          }

          case FORM_GET_DESCRIPTION_SUCCESS:
            return {
              ...state,
              descriptionFile : payload
            }
      
          case FORM_GET_DESCRIPTION_FAILURE:
            return {
              descriptionFile : null
            }

            case FORM_GET_FILE_SUCCESS:
              return {
                ...state,
                formFile : payload
              }
        
            case FORM_GET_FILE_FAILURE:
              return {
                formFile : null
              }

              case FORM_GET_FILE_OFFLINE_SUCCESS:
                return {
                  ...state,
                  offlineFile : payload
                }
          
              case FORM_GET_FILE_OFFLINE_FAILURE:
                return {
                  formFile : null
                }

    default:
      return state;
  }
}
