import React, { useRef, useState } from "react";
import Select from "react-select"; // Import react-select
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../App.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const clientOptions = [
  { value: "client1", label: "Client 1" },
  { value: "client2", label: "Client 2" },
];

const sapOptions = [
  { value: "sap1", label: "SAP ID 1" },
  { value: "sap2", label: "SAP ID 2" },
];

const packageOptions = [
  { value: "package1", label: "Package No 1" },
  { value: "package2", label: "Package No 2" },
];

const locationOptions = [
  { value: "location1", label: "Location 1" },
  { value: "location2", label: "Location 2" },
];

export const FormForDistributor = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [formFields, setFormFields] = useState([
    {
      clientName: null,
      sapId: null,
      packageNo: null,
      totalPhyQnty: "",
      damageQnty: "",
      expiredQnty: "",
      expiryDate: "",
      location: null,
      photos: null,
      remarks: "",
      physicalCountDate: "",
      itemNo: "",
      description: "",
      uom: "",
      itemGroupName: "",
      itemSubGroup3: "",
      itemSubGroup1: "",
      customFields: [],
    },
  ]);
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  const handleFormChange = (index, event) => {
    const data = [...formFields];
    if (event.target.name === "photos") {
      event.preventDefault();
      data[index][event.target.name] = event.target.files[0];
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setFormFields(data);
  };

  const handleSelectChange = (index, name, selectedOption) => {
    const data = [...formFields];
    data[index][name] = selectedOption;
    setFormFields(data);
  };

  const addCustomField = (index) => {
    const data = [...formFields];
    data[index].customFields.push({ name: "", value: "" });
    setFormFields(data);
  };

  const handleCustomFieldChange = (index, customIndex, event) => {
    const data = [...formFields];
    data[index].customFields[customIndex][event.target.name] =
      event.target.value;
    setFormFields(data);
  };

  const deleteCustomField = (index, customIndex) => {
    const data = [...formFields];
    data[index].customFields.splice(customIndex, 1);
    setFormFields(data);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formFields);
  };
  const handleFileChange = (index, event) => {
    event.preventDefault();
    const files = Array.from(event.target.files);
    const data = [...formFields];

    // Ensure data[index].photos is an array before appending files
    data[index].photos = [...(data[index].photos || []), ...files];

    setFormFields(data);
  };

  const deletePhoto = (formIndex, photoIndex) => {
    const data = [...formFields];
    data[formIndex].photos.splice(photoIndex, 1);
    setFormFields(data);
  };

  return (
    <div className="form-container mt-2">
      <form onSubmit={submitForm}>
        {formFields.map((form, index) => (
          <div key={index} className="border p-4 mb-4 rounded bg-light">
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>CLIENT</label>
                <Select
                  options={clientOptions}
                  value={form.clientName}
                  onChange={(selectedOption) =>
                    handleSelectChange(index, "clientName", selectedOption)
                  }
                  isClearable
                />
              </div>
            </div>
         <p className="title-text d-flex justify-content-center">
  PHYSICAL STOCK VERIFICATION SHEET
</p>
          
            <div className="form-row">
            <div className="form-group col-md-1 d-flex">
                <div className="toggle-box" onClick={handleToggle}>
                  {isChecked && <span className="checkmark mb-2">✔️</span>}
                </div>
                <div className="toggle-label">
                  <label>NSI</label>
                </div>
              </div>
              <div className="form-group col-md-3">
                <label>SAP CODE/ITEM NO</label>
                <Select
                  options={sapOptions}
                  value={form.sapId}
                  onChange={(selectedOption) =>
                    handleSelectChange(index, "sapId", selectedOption)
                  }
                  isClearable
                />
              </div>
              <div className="form-group col-md-2">
                <label>PHY. COUNT DATE</label>
                <input
                  type="date"
                  className="form-control"
                  name="expiryDate"
                  value={form.expiryDate}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
              <div className="form-group col-md-2">
                <label>AUTO ENTRY NO</label>
                <input
                  type="number"
                  className="form-control"
                  name="totalPhyQnty"
                  value={form.totalPhyQnty}
                  onChange={(event) => handleFormChange(index, event)}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label>DESCRIPTION</label>
                <input
                  type="text"
                  className="form-control"
                  name="damageQnty"
                  value={form.damageQnty}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <label>ITEM GROUP NAME</label>
                <input
                  type="text"
                  className="form-control"
                  name="remarks"
                  value={form.remarks}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>

              <div className="form-group col-md-3">
                <label>ITEM SUB-GROUP 2</label>
                <input
                  type="text"
                  className="form-control"
                  name="itemNo"
                  value={form.itemNo}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
              <div className="form-group col-md-3">
                <label>ITEM SUB-GROUP 3</label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  value={form.description}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
              <div className="form-group col-md-3">
                <label>UOM</label>
                <input
                  type="text"
                  className="form-control"
                  name="uom"
                  value={form.uom}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <label>TEAM NAME</label>
                <input
                  type="text"
                  className="form-control"
                  name="itemGroupName"
                  value={form.itemGroupName}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
              <div className="form-group col-md-3">
                <label>CLIENT TEAM NAME</label>
                <input
                  type="text"
                  className="form-control"
                  name="remarks"
                  value={form.remarks}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
              <div className="form-group col-md-3">
                <label>CATEGORY</label>
                <input
                  type="text"
                  className="form-control"
                  name="remarks"
                  value={form.remarks}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
              <div className="form-group col-md-3">
                <label>TOTAL QTY</label>
                <input
                  type="number"
                  className="form-control"
                  name="remarks"
                  value={form.remarks}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
            </div>
            <div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label>LOCATION</label>
                  <input
                    type="text"
                    className="form-control"
                    name="remarks"
                    value={form.remarks}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>DEPARTMENT NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    name="itemNo"
                    value={form.itemNo}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>SHELF NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    value={form.description}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>FLOOR</label>
                  <input
                    type="text"
                    className="form-control"
                    name="remarks"
                    value={form.remarks}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label>ASILE NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    name="itemNo"
                    value={form.itemNo}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>COMPART NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    value={form.description}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
              </div>

              <div className="form-group">
                <label style={{ padding: "5px" }}>PHOTOS</label>
                <input
                  type="file"
                  className="form-control-file"
                  name="photos"
                  onChange={(event) => handleFileChange(index, event)}
                  multiple
                />
                <div className="photo-preview mt-3 d-flex flex-wrap">
                  {/* <div className="photo-preview d-flex flex-wrap"> */}
                  {(form.photos || []).map((photo, photoIndex) => (
                    <div key={photoIndex} className="position-relative m-2">
                      <img
                        src={URL.createObjectURL(photo)}
                        alt={`Uploaded ${photoIndex}`}
                        className="img-thumbnail"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                      <button
                        type="button"
                        onClick={() => deletePhoto(index, photoIndex)}
                        className="btn btn-sm btn-danger position-absolute"
                        style={{ top: 0, right: 0 }}
                        // style={{ top: 0, right: 0, padding: "2px 5px", fontSize: "0.6rem" }}
                      >
                        <i className="bi bi-x" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Custom Fields Section */}
            {form.customFields.map((customField, customIndex) => (
              <div key={customIndex} className="form-row mb-2">
                <div className="form-group col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Custom Field Name"
                    value={customField.name}
                    onChange={(event) =>
                      handleCustomFieldChange(index, customIndex, event)
                    }
                  />
                </div>
                <div className="form-group col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="value"
                    placeholder="Custom Field Value"
                    value={customField.value}
                    onChange={(event) =>
                      handleCustomFieldChange(index, customIndex, event)
                    }
                  />
                </div>
                <button
                  onMouseEnter={() => setHoveredIndex(customIndex)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => deleteCustomField(index, customIndex)}
                  style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  <i
                    className="bi bi-trash"
                    style={{
                      fontSize: "20px",
                      color: hoveredIndex === customIndex ? "red" : "black",
                      transition: "color 0.3s, transform 0.3s",
                      transform:
                        hoveredIndex === customIndex
                          ? "scale(1.2)"
                          : "scale(1)",
                    }}
                  />
                </button>
              </div>
            ))}

            <div className="form-row d-flex justify-content-between align-items-center">
              <div>{/* Other content in the form row, if any */}</div>
              <div>
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => addCustomField(index)}
                  style={{
                    fontSize: "27px",
                    color: "#007bff",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <i className="bi bi-plus-circle" />
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <button type="submit" className="submit-btn btn btn-success">
                  Submit
                </button>
              </div>
              <div className="d-flex justify-content-center mx-4">
                <button type="submit" className="submit-btn btn btn-success">
                  <i class="bi bi-download"></i> Download
                </button>
              </div>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};
