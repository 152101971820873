import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

// Register Service Worker after rendering
if ('serviceWorker' in navigator) {
  const swURL = `${process.env.PUBLIC_URL}/serviceWorker.js`;
  console.log("Service Worker URL:", swURL);

  navigator.serviceWorker.register(swURL)
      .then((registration) => {
          console.warn("Service Worker registered successfully:", registration);

          registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              if (installingWorker) {
                  installingWorker.onstatechange = () => {
                      if (installingWorker.state === 'installed') {
                          if (navigator.serviceWorker.controller) {
                              console.log("New content is available; please refresh.");
                              // Optionally trigger a reload
                              window.location.reload();
                          } else {
                              console.log("Content is cached for offline use.");
                          }
                      }
                  };
              }
          };
      })
      .catch((error) => {
          console.error("Service Worker registration failed:", error);
      });
} else {
  console.warn("Service Worker is not supported in this browser.");
}


