import React from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { Outlet, Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import logo from "../../logo.png";

export const LandingPage = () => {
  const location = useLocation();
  const { pathname } = location;

  const isActive = (path) => pathname === path;

  return (
    <Container>
      {/* Top Navbar */}
      <Navbar expand="md" className="w-100 custom-navbar">
        <div className="w-100 nav-wrapper">
          <Navbar.Brand className="navbar-brand">
            <img src={logo} alt="Triefin" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav>
              <Nav.Link
                as={Link}
                to="/form"
                className={`mx-2 ${isActive("/form") ? "active-link" : ""}`}>
                <i className="bi bi-file-earmark-text-fill me-1"></i> Forms
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/master-file"
                className={`mx-2 ${
                  isActive("/master-file") ? "active-link" : ""
                }`}>
                <i className="bi bi-folder-fill me-1"></i> Raw Master
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/report-page"
                className={`mx-2 ${
                  isActive("/report-page") ? "active-link" : ""
                }`}>
                <i className="bi bi-bar-chart-fill me-1"></i> Reports
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      {/* Main content area */}
      <Row>
        <Col xs={12} className="px-4">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};
