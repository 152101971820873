import React, { useState } from 'react';
import '../../../App.css';
import Pagination from '../../resubale_components/pagination';
import ReportTable from './table';

export const ReportPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const itemsPerPage = 10; // Number of items per page

  const data = [
    { id: 1, name: 'rawanda', description: 'Description 1', date: '20/12/2023' },
    { id: 2, name: 'raw', description: 'Description 2', date: '02/02/2024' },
    { id: 3, name: 'zimwaji', description: 'Description 3', date: '25/03/2024' },
    { id: 4, name: 'rawzim', description: 'Description 4', date: '20/12/2023' },
    { id: 5, name: 'zimwabe', description: 'Description 5', date: '15/01/2024' },
    { id: 6, name: 'chikara', description: 'Description 6', date: '10/11/2024' },
    // More items...
  ];

  // Filter the data based on the search term
  const filteredData = data.filter(item =>
    item.name.toLowerCase().startsWith(searchTerm.toLowerCase()) || 
    item.id.toString().includes(searchTerm)
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle item click to display detailed report
  const handleClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div className="table-container">
      {selectedItem ? (
        <ReportTable selectedItem={selectedItem} onClose={() => setSelectedItem(null)} />
      ) : (
        // Display main report page table if no item is selected
        <>
         <div className='mb-4' style={{color: "#4caf50"}}>List of items</div>
          <div className="filter-container">
            <input
              type="text"
              placeholder="Search by name or id..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Form Details</th>
                <th>Description</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    <button onClick={() => handleClick(item)} className="clickable">
                      {item.name}
                    </button>
                  </td>
                  <td>{item.description}</td>
                  <td>{item.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          /> */}
        </>
      )}
    </div>
  );
};