import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, FormControl } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import "./reports.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { LandingPage } from "../landing.js";
import { useLocation } from "react-router-dom";

export const DayReport = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [flattenedData, setFlattenedData] = useState([]);
  const location = useLocation();
  const dataList = location?.state;

  const [showTableImage, setShowTableImage] = useState(false);
  const [currentTableImage, setCurrentTableImage] = useState(null);
  const [showImageForRow, setShowImageForRow] = useState(null);
  const [showTableImage1, setShowTableImage1] = useState(false);
  const [currentTableImage1, setCurrentTableImage1] = useState(null);
  const [showImageForRow1, setShowImageForRow1] = useState(null);

  const [activeAuditorSigns, setActiveAuditorSigns] = useState([]);
  const [activeClientSigns, setActiveClientSigns] = useState([]);
  const [activeRowImages, setActiveRowImages] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);

  const showClientSignature = (e, autoEntryNo) => {
    e.preventDefault();

    const matchingEntry = dataList?.value
      .map((d) => d[0])
      .find((entry) => entry.auto_entry_no === autoEntryNo);

    if (matchingEntry && matchingEntry.client_team_signature) {
      const file = matchingEntry.client_team_signature;

      // Check if the file is a Blob or File
      if (file instanceof Blob || file instanceof File) {
        // Create an object URL for the image file
        const imageUrl = URL.createObjectURL(file);

        // Add the auto_entry_no's signature to the state
        setActiveClientSigns((prev) => [
          ...prev,
          { autoEntryNo, signUrl: imageUrl },
        ]);
      } else {
        console.error("Invalid file format. Expected a Blob or File object.");
      }
    } else {
      console.error(`No signature found for auto_entry_no: ${autoEntryNo}.`);
    }
  };

  const downloadClientSign = (e) => {
    e.preventDefault(); // Prevent any parent click handlers from being triggered

    const signUrl = e.target
      .closest(".signature-container")
      .querySelector("img").src;

    if (signUrl) {
      // Create an invisible link element to trigger the download
      const link = document.createElement("a");
      link.href = signUrl;
      link.download = "client_signature.png"; // You can customize the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Signature URL not found");
    }
  };

  const downloadAuditorSign = (e) => {
    e.preventDefault(); // Prevent any parent click handlers from being triggered

    const signUrl = e.target
      .closest(".signature-container")
      .querySelector("img").src;

    if (signUrl) {
      // Create an invisible link element to trigger the download
      const link = document.createElement("a");
      link.href = signUrl;
      link.download = "auditor_signature.png"; // You can customize the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Signature URL not found");
    }
  };

  const showAuditorSignature = (e, autoEntryNo) => {
    e.preventDefault();
    const matchingEntry = dataList?.value
      .map((d) => d[0])
      .find((entry) => entry.auto_entry_no === autoEntryNo);

    if (matchingEntry && matchingEntry.client_team_signature1) {
      const file = matchingEntry.client_team_signature1;

      // Check if the file is a Blob or File
      if (file instanceof Blob || file instanceof File) {
        // Create an object URL for the image file
        const imageUrl = URL.createObjectURL(file);

        // Add the auto_entry_no's signature to the state
        setActiveAuditorSigns((prev) => [
          ...prev,
          { autoEntryNo, signUrl: imageUrl },
        ]);
      } else {
        console.error("Invalid file format. Expected a Blob or File object.");
      }
    } else {
      console.error(`No signature found for auto_entry_no: ${autoEntryNo}.`);
    }
  };

  const downloadImages = (e, imageUrl) => {
    e.stopPropagation(); // Prevent any parent click handlers from being triggered

    if (imageUrl) {
      // Create an invisible link element to trigger the download
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = imageUrl.split("/").pop(); // Use the image name as the download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Image URL not found");
    }
  };

  const downloadTableImage = (e) => {
    e.stopPropagation(); // Prevent any parent click handlers from being triggered

    const imageUrl = currentTableImage; // Get the current image URL for Table Image

    if (imageUrl) {
      // Create an invisible link element to trigger the download
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = imageUrl.split("/").pop(); // Use the image name as the download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Table Image URL not found");
    }
  };

  const downloadTableImage1 = (e) => {
    e.stopPropagation(); // Prevent any parent click handlers from being triggered

    const imageUrl = currentTableImage1; // Get the current image URL for Table Image1

    if (imageUrl) {
      // Create an invisible link element to trigger the download
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = imageUrl.split("/").pop(); // Use the image name as the download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Table Image1 URL not found");
    }
  };

  console.log(dataList, "dddddddddddd");

  const handleImages = (e, autoEntryNo) => {
    e.preventDefault();
    const matchingEntries = dataList?.value
      .map((d) => d[0])
      .filter((entry) => entry.auto_entry_no === autoEntryNo);

    if (matchingEntries.length > 0) {
      const ImageList = matchingEntries.map((entry) => entry?.images || []);

      const imageUrls = ImageList.flat()
        .map((imageObj) => {
          if (imageObj) {
            // Check if there is a valid 'file' (File or Blob)
            if (
              imageObj.file &&
              (imageObj.file instanceof Blob || imageObj.file instanceof File)
            ) {
              return URL.createObjectURL(imageObj.file); // Create URL for Blob or File
            }
            // If there is a valid 'image' (Blob URL), use it directly
            else if (imageObj.image) {
              return imageObj.image; // Use the existing URL
            }
          }
          return null; // Handle invalid cases
        })
        .filter((url) => url !== null); // Remove null values

      if (imageUrls.length > 0) {
        // Add the auto_entry_no's images to the activeRowImages state
        setActiveRowImages((prev) => [
          ...prev,
          { autoEntryNo, images: imageUrls },
        ]);
      } else {
        console.error("No valid images found for this auto_entry_no.");
      }
    } else {
      console.error(
        `No matching entry found for auto_entry_no: ${autoEntryNo}.`
      );
    }
  };

  const handleTableImage = (e, index) => {
    e.preventDefault();
    const tabledata = dataList?.value.map((d) => d[0]?.tabledata).flat();
    const tableImage = tabledata[index]?.image;

    if (tableImage) {
      if (tableImage instanceof Blob || tableImage instanceof File) {
        // Create an object URL for the image file
        const imageUrl = URL.createObjectURL(tableImage);
        setCurrentTableImage(imageUrl); // Set the image to display for that specific row
        setShowImageForRow(index); // Set the current row to show the image
        setShowTableImage(true); // Show the image viewer for that specific row
      } else if (typeof tableImage === "string") {
        // If it's already a URL, use it directly
        setCurrentTableImage(tableImage);
        setShowImageForRow(index); // Set the current row to show the image
        setShowTableImage(true);
      } else {
        console.error(
          "Invalid file format. Expected a Blob, File, or URL string."
        );
      }
    } else {
      console.error("No image found for this row.");
    }
  };

  const closeTableImage = () => {
    setShowTableImage(false);
    setCurrentTableImage(null);
    setShowImageForRow(null); // Reset which row's image is shown
  };

  const handleTableImage1 = (e, index) => {
    e.preventDefault();
    const tabledata = dataList?.value.map((d) => d[0]?.tabledata).flat();
    const tableImage = tabledata[index]?.image1;

    if (tableImage) {
      if (tableImage instanceof Blob || tableImage instanceof File) {
        // Create an object URL for the image file
        const imageUrl = URL.createObjectURL(tableImage);
        setCurrentTableImage1(imageUrl); // Set the image to display for that specific row
        setShowImageForRow1(index); // Set the current row to show the image
        setShowTableImage1(true); // Show the image viewer for that specific row
      } else if (typeof tableImage === "string") {
        // If it's already a URL, use it directly
        setCurrentTableImage1(tableImage);
        setShowImageForRow1(index); // Set the current row to show the image
        setShowTableImage1(true);
      } else {
        console.error(
          "Invalid file format. Expected a Blob, File, or URL string."
        );
      }
    } else {
      console.error("No image found for this row.");
    }
  };

  const closeTableImage1 = () => {
    setShowTableImage1(false);
    setCurrentTableImage1(null);
    setShowImageForRow1(null);
  };

  const handleShowPdf = (e, autoEntryNo) => {
    e.preventDefault();

    // Find the matching entry based on auto_entry_no
    const matchingEntry = dataList?.value
      .map((d) => d[0])
      .find((entry) => entry.auto_entry_no === autoEntryNo);

    if (matchingEntry && matchingEntry.document) {
      const pdfFile = matchingEntry.document;

      // Check if the document is a valid Blob or File
      if (pdfFile instanceof Blob || pdfFile instanceof File) {
        // Create an object URL for the PDF file
        const pdfUrl = URL.createObjectURL(pdfFile);

        // Open the PDF in a new tab
        window.open(pdfUrl, "_blank");
      } else {
        console.error("Invalid file format. Expected a Blob or File object.");
      }
    } else {
      console.error(`No document found for auto_entry_no: ${autoEntryNo}.`);
    }
  };

  useEffect(() => {
    if (dataList && dataList.value) {
      const data = dataList.value || [];

      const flattened = data.flatMap((row, index) => {
        const baseData = {
          srNo: index + 1,
          formType: row[0].formType || "NA",
          physical_count_date: row[0].physical_count_date || "NA",
          auto_entry_no: row[0].auto_entry_no || "NA",
          client: row[0].client || "NA",
          sap_code_or_item_no: row[0].sap_code_or_item_no || "NA",
          nsi: row[0].nsi || "NA",
          description: row[0].description || "NA",
          item_group_name: row[0].item_group_name || "NA",
          item_sub_group_2: row[0].item_sub_group_2 || "NA",
          item_sub_group_3: row[0].item_sub_group_3 || "NA",
          aisle_name: row[0].aisle_name || "NA",
          floor: row[0].floor || "NA",
          compartment_name: row[0].compartment_name || "NA",
          department_name: row[0].department_name || "NA",
          mixbox: row[0].mixbox || "NA",
          shelf_name: row[0].shelf_name || "NA",
          location: row[0].location || "NA",
          client_team_name: row[0].client_team_name || "NA",
          team_name: row[0].team_name || "NA",
          total_qty: row[0].total_qty || "NA",
        };

        if (row[0].tabledata && Array.isArray(row[0].tabledata)) {
          return row[0].tabledata.map((tableItem) => ({
            ...baseData,
            package_no: tableItem.package_no || "NA",
            physical_qty: tableItem.physical_qty || "NA",
            uom_physical: tableItem.uom_physical || "NA",
            batch_no: tableItem.batch_no || "NA",
            expiry_date: tableItem.expiry_date || "NA",
            condition: tableItem.condition || "NA",
            auditor_observation: tableItem.auditor_observation || "NA",
            auditor_observation_class:
              tableItem.auditor_observation_class || "NA",
            mansa_remarks: tableItem.mansa_remarks || "NA",
            senior_remarks: tableItem.senior_remarks || "NA",
            more_info_type_1: tableItem.more_info_type_1 || "NA",
            more_info_type_2: tableItem.more_info_type_2 || "NA",
            more_info_type_3: tableItem.more_info_type_3 || "NA",
          }));
        }

        return [baseData];
      });

      setFlattenedData(flattened);
    }
  }, [dataList]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const downloadExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "data.xlsx");
  };

  const columns = [
    { name: "Sr. No.", selector: (row) => row.srNo, sortable: true },
    {
      name: "Form Type",
      selector: (row) => row.formType,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.physical_count_date,
      sortable: true,
    },
    {
      name: "Auto Entry No",
      selector: (row) => row.auto_entry_no,
      sortable: true,
    },
    { name: "Client Name", selector: (row) => row.client, sortable: true },
    {
      name: "SAP Code",
      selector: (row) => row.sap_code_or_item_no,
      sortable: true,
    },
    { name: "NSI", selector: (row) => row.nsi, sortable: true },
    {
      name: "Item Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Item Group",
      selector: (row) => row.item_group_name,
      sortable: true,
    },
    {
      name: "Item Sub Group 2",
      selector: (row) => row.item_sub_group_2,
      sortable: true,
    },
    {
      name: "Item Sub Group 3",
      selector: (row) => row.item_sub_group_3,
      sortable: true,
    },
    { name: "Aisle Name", selector: (row) => row.aisle_name, sortable: true },
    { name: "Floor", selector: (row) => row.floor, sortable: true },
    {
      name: "Compartment Name",
      selector: (row) => row.compartment_name,
      sortable: true,
    },
    {
      name: "Department Name",
      selector: (row) => row.department_name,
      sortable: true,
    },
    { name: "Mixbox", selector: (row) => row.mixbox, sortable: true },
    { name: "Storage Name", selector: (row) => row.shelf_name, sortable: true },
    { name: "Location", selector: (row) => row.location, sortable: true },
    {
      name: "Client Team",
      selector: (row) => row.client_team_name,
      sortable: true,
    },
    { name: "Team Name", selector: (row) => row.team_name, sortable: true },
    {
      name: "Total Quantity",
      selector: (row) => row.total_qty,
      sortable: true,
    },
    { name: "Package No", selector: (row) => row.package_no, sortable: true },
    {
      name: "Physical Quantity",
      selector: (row) => row.physical_qty,
      sortable: true,
    },
    { name: "UOM", selector: (row) => row.uom_physical, sortable: true },
    { name: "Batch No", selector: (row) => row.batch_no, sortable: true },
    { name: "Expiry Date", selector: (row) => row.expiry_date, sortable: true },
    { name: "Condition", selector: (row) => row.condition, sortable: true },
    {
      name: "Auditor Observation",
      selector: (row) => row.auditor_observation,
      sortable: true,
    },
    {
      name: "Auditor Observation Class",
      selector: (row) => row.auditor_observation_class,
      sortable: true,
    },
    {
      name: "Mansa Remarks",
      selector: (row) => row.mansa_remarks,
      sortable: true,
    },
    {
      name: "Senior Remarks",
      selector: (row) => row.senior_remarks,
      sortable: true,
    },
    {
      name: "More Info 1",
      selector: (row) => row.more_info_type_1,
      sortable: true,
    },
    {
      name: "More Info 2",
      selector: (row) => row.more_info_type_2,
      sortable: true,
    },
    {
      name: "More Info 3",
      selector: (row) => row.more_info_type_3,
      sortable: true,
    },
    {
      name: "Client Signature",
      cell: (row) => (
        <div className="action-btn-group">
          {activeClientSigns.some(
            (r) => r.autoEntryNo === row.auto_entry_no
          ) ? (
            <>
              <div className="d-flex">
                <div className="signature-container p-0 m-0">
                  {activeClientSigns.find(
                    (r) => r.autoEntryNo === row.auto_entry_no
                  )?.signUrl && (
                    <div style={{ display: "flex" }}>
                      <img
                        src={
                          activeClientSigns.find(
                            (r) => r.autoEntryNo === row.auto_entry_no
                          ).signUrl
                        }
                        alt="Client Signature"
                        className="signature-img"
                      />
                      <span style={{ margin: "10px", cursor: "pointer" }}>
                        <i
                          class="bi bi-download fs-5"
                          onClick={(e) => downloadClientSign(e)}
                        ></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <button
                className="close-icon"
                onClick={() =>
                  setActiveClientSigns((prev) =>
                    prev.filter((r) => r.autoEntryNo !== row.auto_entry_no)
                  )
                }
              >
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => showClientSignature(e, row.auto_entry_no)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Auditor Sign",
      cell: (row) => (
        <div className="action-btn-group">
          {activeAuditorSigns.some(
            (r) => r.autoEntryNo === row.auto_entry_no
          ) ? (
            <>
              <div className="d-flex">
                <div className="signature-container p-0 m-0">
                  {activeAuditorSigns.find(
                    (r) => r.autoEntryNo === row.auto_entry_no
                  )?.signUrl && (
                    <div style={{ display: "flex" }}>
                      <img
                        src={
                          activeAuditorSigns.find(
                            (r) => r.autoEntryNo === row.auto_entry_no
                          ).signUrl
                        }
                        alt="Auditor Signature"
                        className="signature-img"
                      />
                      <span style={{ margin: "10px", cursor: "pointer" }}>
                        <i
                          class="bi bi-download fs-5"
                          onClick={(e) => downloadAuditorSign(e)}
                        ></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <button
                className="close-icon"
                onClick={() =>
                  setActiveAuditorSigns((prev) =>
                    prev.filter((r) => r.autoEntryNo !== row.auto_entry_no)
                  )
                }
              >
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => showAuditorSignature(e, row.auto_entry_no)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Images",
      cell: (row) => (
        <div className="action-btn-group">
          {activeRowImages.some((r) => r.autoEntryNo === row.auto_entry_no) ? (
            <>
              <div className="d-flex">
                <div className="imageList-container">
                  {activeRowImages
                    .find((r) => r.autoEntryNo === row.auto_entry_no)
                    ?.images.map((url, imgIndex) => (
                      <div key={imgIndex} style={{ position: "relative" }}>
                        <img
                          src={url}
                          alt={`Image ${imgIndex + 1}`}
                          className="images-img"
                        />
                        <span
                          style={{
                            position: "absolute",
                            margin: "8px",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            className="bi bi-download fs-5"
                            onClick={(e) => downloadImages(e, url)} // Trigger download for this image
                          ></i>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <button
                className="close-icon"
                onClick={() =>
                  setActiveRowImages((prev) =>
                    prev.filter((r) => r.autoEntryNo !== row.auto_entry_no)
                  )
                }
              >
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => handleImages(e, row.auto_entry_no)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },

    {
      name: "Doc Pdf",
      cell: (row) => (
        <div className="action-btn-group">
          <a
            className="ff-plus-jakarta btn btn-view"
            type="button"
            onClick={(e) => handleShowPdf(e, row.auto_entry_no)}
          >
            <i className="bi bi-eye"></i> View
          </a>
        </div>
      ),
    },
    {
      name: "Table Image",
      cell: (row, index) => (
        <div className="action-btn-group">
          {showTableImage && currentTableImage && showImageForRow === index ? ( // Check if the clicked row's image should be displayed
            <>
              <div className="d-flex">
                <div className="image-container">
                  <img
                    src={currentTableImage}
                    alt="Table Image"
                    className="signature-img"
                  />
                </div>
              </div>
              <span
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <i
                  className="bi bi-download fs-5"
                  onClick={downloadTableImage} // Trigger download for Table Image
                ></i>
              </span>
              <button className="close-icon" onClick={closeTableImage}>
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => handleTableImage(e, index)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Table Image1",
      cell: (row, index) => (
        <div className="action-btn-group">
          {showTableImage1 &&
          currentTableImage1 &&
          showImageForRow1 === index ? ( // Check if the clicked row's image should be displayed
            <>
              <div className="d-flex">
                <div className="image-container">
                  <img
                    src={currentTableImage1}
                    alt="Table Image1"
                    className="signature-img"
                  />
                </div>
              </div>
              <span
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <i
                  className="bi bi-download fs-5"
                  onClick={downloadTableImage1} // Trigger download for Table Image1
                ></i>
              </span>
              <button className="close-icon" onClick={closeTableImage1}>
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => handleTableImage1(e, index)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Excel",
      cell: (row) => (
        <DropdownButton
          title={<i className="bi bi-download"></i>}
          className="btn btn-download"
        >
          <Dropdown.Item onClick={() => downloadExcel([row])}>
            Excel
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
    // {
    //   name: "Delete",
    //   cell: (row) => (
    //     <button
    //       className="btn btn-danger"
    //       onClick={() => handleDeleteRow(row)}
    //     >
    //       Delete
    //     </button>
    //   ),
    // },
    
  ];

  const filteredData = flattenedData?.filter((row) =>
    row.auto_entry_no.toLowerCase().includes(searchTerm.toLowerCase())
  );

//   const handleDeleteRow = async (row) => {
//     const dbName = `FormDataDB_${localStorage.getItem("username")}`;
//   // Remove from the current data in state
//   setFilteredData((prevData) => prevData.filter((item) => item.auto_entry_no !== row.auto_entry_no));

//   // Open IndexedDB and delete the record
//   const dbRequest = indexedDB.open(dbName, 2);

//   console.log(row,"rowrf")

//   dbRequest.onsuccess = (event) => {
//     const db = event.target.result;
//     const transaction = db.transaction(["formData"], "readwrite");
//     const objectStore = transaction.objectStore("formData");

//     const deleteRequest = objectStore.delete("id");

//     console.log(row.auto_entry_no,"iiiiiiiiiii")

//     deleteRequest.onsuccess = () => {
//       console.log("Row successfully deleted from IndexedDB");
//     };

//     deleteRequest.onerror = (error) => {
//       console.error("Error deleting row from IndexedDB:", error.target.error);
//     };
//   };

//   dbRequest.onerror = (error) => {
//     console.error("Error opening IndexedDB:", error.target.error);
//   };
// };

  

  return (
    <>
      <LandingPage />
      <div className="reports-wrapper">
        <div className="container">
          <div className="w-100 row mb-3">
            <div className="col-12 col-md-6">
              <h1 className="page-title">List of items</h1>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group--search">
                <FormControl
                  type="text"
                  placeholder="Search by name or id..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <i className="bi bi-search"></i>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />

        </div>
      </div>
    </>
  );
};
