import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./master.css";
// import DownloadComponent from './download';
import Papa from "papaparse";
import {
  storeDataInIndexedDB,
  fetchDataFromIndexedDB,
} from "../../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { LandingPage } from "../landing";

export const MasterFile = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");

  const openDB = () => {
    const dbName = `CSVDataStore_${localStorage.getItem("username")}`;
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (e) => {
        const db = e.target.result;
        if (!db.objectStoreNames.contains("csvData")) {
          db.createObjectStore("csvData", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (e) => {
        resolve(e.target.result);
      };

      request.onerror = (e) => {
        reject("IndexedDB error: " + e.target.error);
      };
    });
  };

  const storeDataInIndexedDB = (data) => {
    openDB()
      .then((db) => {
        const transaction = db.transaction("csvData", "readwrite");
        const store = transaction.objectStore("csvData");
        store.clear(); // Clear the store before adding new data (optional)

        data.forEach((item) => {
          store.add(item);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      Papa.parse(file, {
        complete: (result) => {
          // Store the parsed CSV data in IndexedDB
          storeDataInIndexedDB(result.data);

          // Update state to display the parsed data
          setData(result.data);
          toast.success("file successfully uploaded");
        },
        header: true, // Assuming CSV has headers
      });
    } else {
      setMessage("Please upload a valid CSV file");
      toast.error("error on uploading file");
    }
  };

  console.log("data from csv", data);

  const fetchDataFromIndexedDB = () => {
    return new Promise((resolve, reject) => {
      openDB()
        .then((db) => {
          const transaction = db.transaction("csvData", "readonly");
          const store = transaction.objectStore("csvData");

          const request = store.getAll(); // Get all the data stored in the object store
          request.onsuccess = () => {
            resolve(request.result);
          };
          request.onerror = (e) => {
            reject("Failed to fetch data from IndexedDB: " + e.target.error);
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDataFromIndexedDB = () => {
    fetchDataFromIndexedDB()
      .then((storedData) => {
        setData(storedData);
        setMessage("Data retrieved from IndexedDB");
      })
      .catch((error) => {
        setMessage("Error retrieving data: " + error);
      });
  };

  useEffect(() => {
    const userId = localStorage.getItem("username"); 
    if(userId){
      getDataFromIndexedDB(userId);
    }
    
  }, [data?.length]);

  return (
    <>
      <LandingPage />
      <div className="upload-container p-0">
        <div className="py-0 container py-0">
          <form className="upload-form">
            <label htmlFor="file" className="upload-label">
              <input
                type="file"
                id="file"
                className="file-input"
                accept=".csv"
                onChange={handleFileUpload}
              />
              <span className="file-button">Choose File</span>
              <span> {file?.name}</span>
            </label>
            {/* <button type="submit" className="submit-button">Upload</button> */}
            {/* <button onClick={(e)=>getDataFromIndexedDB(e)}>Get Data from IndexedDB</button> */}
          </form>
          {/* <DownloadComponent /> */}
          <ToastContainer />
        </div>
      </div>
    </>
  );
};
