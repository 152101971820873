export const FORM_POST_SUCCESS = "FORM/FORM_POST_SUCCESS";
export const FORM_POST_FAILURE = "FORM/FORM_POST_FAILURE";
export const FORM_GET_SUCCESS = "FORM/FORM_GET_SUCCESS";
export const FORM_GET_FAILURE = "FORM/FORM_GET_FAILURE";
export const FORM_GET_ITEM_MASTER_FILE_SUCCESS = "FORM/FORM_GET_ITEM_MASTER_FILE_SUCCESS";
export const FORM_GET_ITEM_MASTER_FILE_FAILURE = "FORM/FORM_GET_ITEM_MASTER_FILE_FAILURE";

export const FORM_GET_SAP_SUCCESS = "FORM/FORM_GET_SAP_SUCCESS";
export const FORM_GET_SAP_FAILURE = "FORM/FORM_GET_SAP_FAILURE";
export const FORM_GET_DESCRIPTION_SUCCESS = "FORM/FORM_GET_DESCRIPTION_SUCCESS";
export const FORM_GET_DESCRIPTION_FAILURE = "FORM/FORM_GET_DESCRIPTION_FAILURE";
export const FORM_GET_FILE_SUCCESS = "FORM/FORM_GET_FILE_SUCCESS";
export const FORM_GET_FILE_FAILURE = "FORM/FORM_GET_FILE_FAILURE";
export const FORM_GET_FILE_OFFLINE_SUCCESS = "FORM/FORM_GET_FILE_OFFLINE_SUCCESS";
export const FORM_GET_FILE_OFFLINE_FAILURE = "FORM/FORM_GET_FILE_OFFLINE_FAILURE";
