import React, { useState } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormForDistributor } from './form/distributorForm';
import '../../App.css';
import { ReportPage } from "./report";
import { MasterFile } from "./masterFile/index";

export const AdminLandingPage = () => {
  const [activeTab, setActiveTab] = useState("form");

  const renderContent = () => {
    switch (activeTab) {
      case "form":
        return <FormForDistributor />;
        case "master":
          return <MasterFile />;
      case "variance":
        return <div><p style={{color: "#4caf50"}}>check variance here</p><p>This is where variance will be calculated.</p></div>;
      case "reports":
        return <ReportPage />;
      default:
        return <div><h2>Select an option from the sidebar</h2></div>;
    }
  };

  return (
    <Container fluid>
      <Row>
        {/* Sidebar - collapses on small screens */}
        <Col xs={12} md={3} className="bg-light sidebar">
          <h4 className="text-center sidebar-title">Admin</h4>
          <Nav defaultActiveKey="/home" className="flex-column">
            <Nav.Link
              href="#"
              active={activeTab === "form"}
              onClick={() => setActiveTab("form")}
              className="sidebar-link"
            >
              Forms
            </Nav.Link>
            <Nav.Link
              href="#"
              active={activeTab === "master"}
              onClick={() => setActiveTab("master")}
              className="sidebar-link"
            >
             Master File
            </Nav.Link>
            <Nav.Link
              href="#"
              active={activeTab === "variance"}
              onClick={() => setActiveTab("variance")}
              className="sidebar-link"
            >
             Check Variance
            </Nav.Link>
            <Nav.Link
              href="#"
              active={activeTab === "reports"}
              onClick={() => setActiveTab("reports")}
              className="sidebar-link"
            >
              Reports
            </Nav.Link>
          </Nav>
        </Col>
        
        {/* Main content area */}
        <Col xs={12} md={9} className="main-content">
          {renderContent()}
        </Col>
      </Row>
    </Container>
  );
};
