import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import "./reports.css";
import { saveAs } from "file-saver";
import "bootstrap/dist/css/bootstrap.min.css";
import { LandingPage } from "../landing.js";
import { useNavigate } from "react-router-dom";

export const ReportPage = () => {
  const [formDataList, setFormDataList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [groupedData, setGroupedData] = useState([]);

  const navigate = useNavigate();

  const openDB = () => {
    const dbName = `FormDataDB_${localStorage.getItem("username")}`;
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 2);

      request.onupgradeneeded = (e) => {
        const db = e.target.result;
        if (!db.objectStoreNames.contains("formData")) {
          db.createObjectStore("formData", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (e) => {
        resolve(e.target.result);
      };

      request.onerror = (e) => {
        reject("IndexedDB error: " + e.target.error);
      };
    });
  };

  const fetchDataFromIndexedDB = () => {
    return new Promise((resolve, reject) => {
      openDB()
        .then((db) => {
          const transaction = db.transaction("formData", "readonly");
          const store = transaction.objectStore("formData");

          const request = store.getAll(); // Get all the data stored in the object store
          request.onsuccess = () => {
            resolve(request.result);
          };
          request.onerror = (e) => {
            reject("Failed to fetch data from IndexedDB: " + e.target.error);
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDataFromIndexedDB = () => {
    fetchDataFromIndexedDB()
      .then((storedData) => {
        setFormDataList(storedData);
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    const userId = localStorage.getItem("username");
    if(userId){
      getDataFromIndexedDB(userId);
    }
   
  }, [formDataList?.length]);

  const filterFormWithDate = () => {
    const grouped = formDataList?.reduce((acc, data) => {
      const date = data[0].physical_count_date;

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(data);
      return acc;
    }, {});

    if (grouped) {
      const groupedArray = Object.entries(grouped).map(([date, data]) => ({
        label: date,
        value: data,
      }));
      setGroupedData(groupedArray);
    }
  };

  useEffect(() => {
    if (formDataList) {
      filterFormWithDate();
    }
  }, [formDataList?.length]);

  console.log(groupedData, "gggggggggg");

  // Handle search box input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const downloadExcel = (data) => {
    const excludedFields = [
      'client_team_signature',
      'client_team_signature1',
      'document',
      'images',
      'documents',
      'image',
      'image1'
    ];
  
    const flattenData = (entry) => {
      // Remove unwanted fields from the entry
      return Object.fromEntries(
        Object.entries(entry).filter(([key]) => !excludedFields.includes(key))
      );
    };
  
    const flattenedData = data.flatMap((item) =>
      item.value.flatMap((entry) => {
        const otherFields = entry[0] || {}; // Get the other fields (excluding tableData)
        const tableData = otherFields.tabledata || []; // Extract tableData, default to empty array if undefined
  
        // Filter unwanted fields from otherFields
        const cleanedOtherFields = flattenData(otherFields);
  
        // Flatten tableData and filter unwanted fields from each table entry
        return tableData.map((tableEntry, index) => ({
          Date: item.label,
          TableDataIndex: index + 1, // Optional: Index of table data entry
          ...cleanedOtherFields,
          ...flattenData(tableEntry), // Remove unwanted fields from table data
        }));
      })
    );

    // Create a worksheet from the flattened data
    const ws = XLSX.utils.json_to_sheet(flattenedData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write the workbook to a file
    XLSX.writeFile(wb, "data.xlsx");
  };

  const handleNavigate = (e, rowData) => {
    e.preventDefault();
    navigate("/day-report", { state: rowData });
  };

  // Table columns
  const columns = [
    { name: "Sr.No", selector: (row, index) => index + 1 },
    { name: "Date", selector: (row) => row.label, sortable: true },
    {
      name: "Total Forms",
      selector: (row) => row.value.length,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="action-btn-group">
          <a
            className="ff-plus-jakarta btn btn-view"
            type="button"
            onClick={(e) => handleNavigate(e, row)}
          >
            <i className="bi bi-eye"></i> View
          </a>
          <DropdownButton
            title={<i className="bi bi-download"></i>}
            className="ff-plus-jakarta btn btn-download"
          >
            <Dropdown.Item onClick={() => downloadExcel([row])}>
              Download Excel
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];

  const filteredData = groupedData?.filter(
    (row) => row.label.includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <LandingPage />
      <div className="reports-wrapper">
        <div className="container">
          <div className="w-100 row mb-3">
            <div className="col-12 col-md-6">
              <h1 className="ff-plus-jakarta page-title">List of items</h1>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group--search">
                <FormControl
                  type="text"
                  className="ff-plus-jakarta form-control"
                  placeholder="Search by date.."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <i className="bi bi-search"></i>
              </div>
            </div>
          </div>

          <div className="ff-plus-jakarta w-100 row">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
